import React, { useMemo } from 'react'

import SettingsItem from '../../common/views/settingsItem/settingsItem'
import { Alert, Button, LoadingOverlay, PageHeader } from '../../common'
import { useSettings, useSettingsItems } from '../../../hooks'
import { isEmptyObj, SETTINGS_LABELS } from '../../common/form/helper'
import { isRequired } from '../../../common/utils/validators'
import { Severity } from '../../../types'

import { CustomFieldMapper, parseSettingsObject } from './helpers/parsing'

export default function SystemSettings() {
   const {
      error: settingsError,
      isLoading,
      isSuccess,
      isUpdating,
      settings,
      update: updateSettings,
   } = useSettings()
   const {
      changedData,
      errors: customFieldErrors,
      isSubmitted,
      onChangeCustomField,
      onChangeToggle,
      onValidate,
   } = useSettingsItems()
   const handleSave = () => {
      const isValid = onValidate(settings, customFields)

      if (isValid) {
         updateSettings({ ...settings, ...changedData })
      }
   }

   const allowedSettings = [
      'QuantityEnabled',
      'KitEnabled',
      'AuditEnabled',
      'ConsumablesEnabled',
      'EnableLocationTracking',
      'ForceScanToAccept',
   ]

   const customFields: CustomFieldMapper = {
      EnableLocationTracking: {
         key: 'LocationTrackingLabel',
         label: SETTINGS_LABELS.LocationTrackingLabel,
         type: 'text',
         validate: isRequired,
      },
   }

   const parsedSettings = useMemo(
      () => parseSettingsObject(settings, allowedSettings, customFields),
      [settings]
   )

   const renderErrorMessage = useMemo(() => {
      if (!isSubmitted) return null

      if (!isEmptyObj(customFieldErrors)) {
         return 'There are errors with your custom fields, see above'
      }

      return settingsError
   }, [isSubmitted, customFieldErrors, settingsError])

   return (
      <>
         <PageHeader title="System Settings" variant="wideMargin" />

         {isLoading ? (
            <LoadingOverlay />
         ) : (
            <>
               {parsedSettings &&
                  parsedSettings.map((item) => {
                     return (
                        <SettingsItem
                           key={item.id}
                           customField={item?.customField}
                           description={item.description}
                           descriptionLocation={item?.customField?.position}
                           error={customFieldErrors[item?.customField?.key]}
                           id={item.id}
                           isEnabled={
                              changedData.hasOwnProperty(item.id)
                                 ? changedData[item.id]
                                 : item.value
                           }
                           label={item.name}
                           onChangeCustomField={onChangeCustomField}
                           onToggle={onChangeToggle}
                        />
                     )
                  })}

               {renderErrorMessage && <Alert message={renderErrorMessage} />}

               {isSuccess && !renderErrorMessage && (
                  <Alert
                     message="System settings saved successfully"
                     severity={Severity.SUCCESS}
                  />
               )}

               <Button loading={isUpdating} onClick={handleSave}>
                  Save
               </Button>
            </>
         )}
      </>
   )
}
